import { isLocal } from "./isLocal";


if($('#preloader').length > 0){
	if(isLocal()){
		$('#preloader').remove()
	} else {
		$('body').addClass('overflow');
		setTimeout(() => {
			$('#preloader').fadeOut('slow');
			$('body').removeClass('overflow');

		}, 800);
	}
}
