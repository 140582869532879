const axios = require("axios");
const isLocal = (location.hostname === "localhost" || location.hostname === "127.0.0.1" || location.hostname === "");


const countries = [
    { "text": "Afghanistan", "value": "AF" },
    { "text": "Åland Islands", "value": "AX" },
    { "text": "Albania", "value": "AL" },
    { "text": "Algeria", "value": "DZ" },
    { "text": "American Samoa", "value": "AS" },
    { "text": "Andorra", "value": "AD" },
    { "text": "Angola", "value": "AO" },
    { "text": "Anguilla", "value": "AI" },
    { "text": "Antarctica", "value": "AQ" },
    { "text": "Antigua and Barbuda", "value": "AG" },
    { "text": "Argentina", "value": "AR" },
    { "text": "Armenia", "value": "AM" },
    { "text": "Aruba", "value": "AW" },
    { "text": "Australia", "value": "AU" },
    { "text": "Austria", "value": "AT" },
    { "text": "Azerbaijan", "value": "AZ" },
    { "text": "Bahamas", "value": "BS" },
    { "text": "Bahrain", "value": "BH" },
    { "text": "Bangladesh", "value": "BD" },
    { "text": "Barbados", "value": "BB" },
    { "text": "Belarus", "value": "BY" },
    { "text": "Belgium", "value": "BE" },
    { "text": "Belize", "value": "BZ" },
    { "text": "Benin", "value": "BJ" },
    { "text": "Bermuda", "value": "BM" },
    { "text": "Bhutan", "value": "BT" },
    { "text": "Bolivia", "value": "BO" },
    { "text": "Bosnia and Herzegovina", "value": "BA" },
    { "text": "Botswana", "value": "BW" },
    { "text": "Bouvet Island", "value": "BV" },
    { "text": "Brazil", "value": "BR" },
    { "text": "British Indian Ocean Territory", "value": "IO" },
    { "text": "Brunei Darussalam", "value": "BN" },
    { "text": "Bulgaria", "value": "BG" },
    { "text": "Burkina Faso", "value": "BF" },
    { "text": "Burundi", "value": "BI" },
    { "text": "Cambodia", "value": "KH" },
    { "text": "Cameroon", "value": "CM" },
    { "text": "Canada", "value": "CA" },
    { "text": "Cape Verde", "value": "CV" },
    { "text": "Cayman Islands", "value": "KY" },
    { "text": "Central African Republic", "value": "CF" },
    { "text": "Chad", "value": "TD" },
    { "text": "Chile", "value": "CL" },
    { "text": "China", "value": "CN" },
    { "text": "Christmas Island", "value": "CX" },
    { "text": "Cocos (Keeling) Islands", "value": "CC" },
    { "text": "Colombia", "value": "CO" },
    { "text": "Comoros", "value": "KM" },
    { "text": "Congo", "value": "CG" },
    { "text": "Congo, The Democratic Republic of the", "value": "CD" },
    { "text": "Cook Islands", "value": "CK" },
    { "text": "Costa Rica", "value": "CR" },
    { "text": "Cote D'Ivoire", "value": "CI" },
    { "text": "Croatia", "value": "HR" },
    { "text": "Cuba", "value": "CU" },
    { "text": "Cyprus", "value": "CY" },
    { "text": "Czech Republic", "value": "CZ" },
    { "text": "Denmark", "value": "DK" },
    { "text": "Djibouti", "value": "DJ" },
    { "text": "Dominica", "value": "DM" },
    { "text": "Dominican Republic", "value": "DO" },
    { "text": "Ecuador", "value": "EC" },
    { "text": "Egypt", "value": "EG" },
    { "text": "El Salvador", "value": "SV" },
    { "text": "Equatorial Guinea", "value": "GQ" },
    { "text": "Eritrea", "value": "ER" },
    { "text": "Estonia", "value": "EE" },
    { "text": "Ethiopia", "value": "ET" },
    { "text": "Falkland Islands (Malvinas)", "value": "FK" },
    { "text": "Faroe Islands", "value": "FO" },
    { "text": "Fiji", "value": "FJ" },
    { "text": "Finland", "value": "FI" },
    { "text": "France", "value": "FR" },
    { "text": "French Guiana", "value": "GF" },
    { "text": "French Polynesia", "value": "PF" },
    { "text": "French Southern Territories", "value": "TF" },
    { "text": "Gabon", "value": "GA" },
    { "text": "Gambia", "value": "GM" },
    { "text": "Georgia", "value": "GE" },
    { "text": "Germany", "value": "DE" },
    { "text": "Ghana", "value": "GH" },
    { "text": "Gibraltar", "value": "GI" },
    { "text": "Greece", "value": "GR" },
    { "text": "Greenland", "value": "GL" },
    { "text": "Grenada", "value": "GD" },
    { "text": "Guadeloupe", "value": "GP" },
    { "text": "Guam", "value": "GU" },
    { "text": "Guatemala", "value": "GT" },
    { "text": "Guernsey", "value": "GG" },
    { "text": "Guinea", "value": "GN" },
    { "text": "Guinea-Bissau", "value": "GW" },
    { "text": "Guyana", "value": "GY" },
    { "text": "Haiti", "value": "HT" },
    { "text": "Heard Island and Mcdonald Islands", "value": "HM" },
    { "text": "Holy See (Vatican City State)", "value": "VA" },
    { "text": "Honduras", "value": "HN" },
    { "text": "Hong Kong", "value": "HK" },
    { "text": "Hungary", "value": "HU" },
    { "text": "Iceland", "value": "IS" },
    { "text": "India", "value": "IN" },
    { "text": "Indonesia", "value": "ID" },
    { "text": "Iran, Islamic Republic Of", "value": "IR" },
    { "text": "Iraq", "value": "IQ" },
    { "text": "Ireland", "value": "IE" },
    { "text": "Isle of Man", "value": "IM" },
    { "text": "Israel", "value": "IL" },
    { "text": "Italy", "value": "IT" },
    { "text": "Jamaica", "value": "JM" },
    { "text": "Japan", "value": "JP" },
    { "text": "Jersey", "value": "JE" },
    { "text": "Jordan", "value": "JO" },
    { "text": "Kazakhstan", "value": "KZ" },
    { "text": "Kenya", "value": "KE" },
    { "text": "Kiribati", "value": "KI" },
    { "text": "Korea, Democratic People'S Republic of", "value": "KP" },
    { "text": "Korea, Republic of", "value": "KR" },
    { "text": "Kuwait", "value": "KW" },
    { "text": "Kyrgyzstan", "value": "KG" },
    { "text": "Lao People'S Democratic Republic", "value": "LA" },
    { "text": "Latvia", "value": "LV" },
    { "text": "Lebanon", "value": "LB" },
    { "text": "Lesotho", "value": "LS" },
    { "text": "Liberia", "value": "LR" },
    { "text": "Libyan Arab Jamahiriya", "value": "LY" },
    { "text": "Liechtenstein", "value": "LI" },
    { "text": "Lithuania", "value": "LT" },
    { "text": "Luxembourg", "value": "LU" },
    { "text": "Macao", "value": "MO" },
    { "text": "Macedonia, The Former Yugoslav Republic of", "value": "MK" },
    { "text": "Madagascar", "value": "MG" },
    { "text": "Malawi", "value": "MW" },
    { "text": "Malaysia", "value": "MY" },
    { "text": "Maldives", "value": "MV" },
    { "text": "Mali", "value": "ML" },
    { "text": "Malta", "value": "MT" },
    { "text": "Marshall Islands", "value": "MH" },
    { "text": "Martinique", "value": "MQ" },
    { "text": "Mauritania", "value": "MR" },
    { "text": "Mauritius", "value": "MU" },
    { "text": "Mayotte", "value": "YT" },
    { "text": "Mexico", "value": "MX" },
    { "text": "Micronesia, Federated States of", "value": "FM" },
    { "text": "Moldova, Republic of", "value": "MD" },
    { "text": "Monaco", "value": "MC" },
    { "text": "Mongolia", "value": "MN" },
    { "text": "Montserrat", "value": "MS" },
    { "text": "Morocco", "value": "MA" },
    { "text": "Mozambique", "value": "MZ" },
    { "text": "Myanmar", "value": "MM" },
    { "text": "Namibia", "value": "NA" },
    { "text": "Nauru", "value": "NR" },
    { "text": "Nepal", "value": "NP" },
    { "text": "Netherlands", "value": "NL" },
    { "text": "Netherlands Antilles", "value": "AN" },
    { "text": "New Caledonia", "value": "NC" },
    { "text": "New Zealand", "value": "NZ" },
    { "text": "Nicaragua", "value": "NI" },
    { "text": "Niger", "value": "NE" },
    { "text": "Nigeria", "value": "NG" },
    { "text": "Niue", "value": "NU" },
    { "text": "Norfolk Island", "value": "NF" },
    { "text": "Northern Mariana Islands", "value": "MP" },
    { "text": "Norway", "value": "NO" },
    { "text": "Oman", "value": "OM" },
    { "text": "Pakistan", "value": "PK" },
    { "text": "Palau", "value": "PW" },
    { "text": "Palestinian Territory, Occupied", "value": "PS" },
    { "text": "Panama", "value": "PA" },
    { "text": "Papua New Guinea", "value": "PG" },
    { "text": "Paraguay", "value": "PY" },
    { "text": "Peru", "value": "PE" },
    { "text": "Philippines", "value": "PH" },
    { "text": "Pitcairn", "value": "PN" },
    { "text": "Poland", "value": "PL" },
    { "text": "Portugal", "value": "PT" },
    { "text": "Puerto Rico", "value": "PR" },
    { "text": "Qatar", "value": "QA" },
    { "text": "Reunion", "value": "RE" },
    { "text": "Romania", "value": "RO" },
    { "text": "Russian Federation", "value": "RU" },
    { "text": "RWANDA", "value": "RW" },
    { "text": "Saint Helena", "value": "SH" },
    { "text": "Saint Kitts and Nevis", "value": "KN" },
    { "text": "Saint Lucia", "value": "LC" },
    { "text": "Saint Pierre and Miquelon", "value": "PM" },
    { "text": "Saint Vincent and the Grenadines", "value": "VC" },
    { "text": "Samoa", "value": "WS" },
    { "text": "San Marino", "value": "SM" },
    { "text": "Sao Tome and Principe", "value": "ST" },
    { "text": "Saudi Arabia", "value": "SA" },
    { "text": "Senegal", "value": "SN" },
    { "text": "Serbia and Montenegro", "value": "CS" },
    { "text": "Seychelles", "value": "SC" },
    { "text": "Sierra Leone", "value": "SL" },
    { "text": "Singapore", "value": "SG" },
    { "text": "Slovakia", "value": "SK" },
    { "text": "Slovenia", "value": "SI" },
    { "text": "Solomon Islands", "value": "SB" },
    { "text": "Somalia", "value": "SO" },
    { "text": "South Africa", "value": "ZA" },
    { "text": "South Georgia and the South Sandwich Islands", "value": "GS" },
    { "text": "Spain", "value": "ES" },
    { "text": "Sri Lanka", "value": "LK" },
    { "text": "Sudan", "value": "SD" },
    { "text": "Suriname", "value": "SR" },
    { "text": "Svalbard and Jan Mayen", "value": "SJ" },
    { "text": "Swaziland", "value": "SZ" },
    { "text": "Sweden", "value": "SE" },
    { "text": "Switzerland", "value": "CH" },
    { "text": "Syrian Arab Republic", "value": "SY" },
    { "text": "Taiwan, Province of China", "value": "TW" },
    { "text": "Tajikistan", "value": "TJ" },
    { "text": "Tanzania, United Republic of", "value": "TZ" },
    { "text": "Thailand", "value": "TH" },
    { "text": "Timor-Leste", "value": "TL" },
    { "text": "Togo", "value": "TG" },
    { "text": "Tokelau", "value": "TK" },
    { "text": "Tonga", "value": "TO" },
    { "text": "Trinidad and Tobago", "value": "TT" },
    { "text": "Tunisia", "value": "TN" },
    { "text": "Turkey", "value": "TR" },
    { "text": "Turkmenistan", "value": "TM" },
    { "text": "Turks and Caicos Islands", "value": "TC" },
    { "text": "Tuvalu", "value": "TV" },
    { "text": "Uganda", "value": "UG" },
    { "text": "Ukraine", "value": "UA" },
    { "text": "United Arab Emirates", "value": "AE" },
    { "text": "United Kingdom", "value": "GB" },
    { "text": "United States", "value": "US", synonym: ['USA','United States of America'] },
    { "text": "United States Minor Outlying Islands", "value": "UM" },
    { "text": "Uruguay", "value": "UY" },
    { "text": "Uzbekistan", "value": "UZ" },
    { "text": "Vanuatu", "value": "VU" },
    { "text": "Venezuela", "value": "VE" },
    { "text": "Viet Nam", "value": "VN" },
    { "text": "Virgin Islands, British", "value": "VG" },
    { "text": "Virgin Islands, U.S.", "value": "VI" },
    { "text": "Wallis and Futuna", "value": "WF" },
    { "text": "Western Sahara", "value": "EH" },
    { "text": "Yemen", "value": "YE" },
    { "text": "Zambia", "value": "ZM" },
    { "text": "Zimbabwe", "value": "ZW" }
];

for (var i = 0; i < countries.length; i++) {
    countries[i].image = 'https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/' + countries[i].value.toLowerCase() + '.svg';
}

const outerSelect = document.getElementById('outerSelect');

if(!!outerSelect){
	const wrapper = outerSelect.closest('.form__field');
	jSuites.dropdown(outerSelect, {
		data: countries,
		autocomplete: true,
		multiple: false,
		// type:'picker',
		width: '280px',
		onopen: function() {
			const errorMessage = outerSelect.parentElement.querySelector(".error");

			wrapper.classList.add('form__field_active');
			outerSelect.classList.remove('input-error');

			// console.log(errorMessage);
			errorMessage.classList.add("hidden");
		},
		onclose: function() {
			// console.log(this.value);
			if(!!this.value){
				wrapper.classList.add('form__field_active');
			} else {
				wrapper.classList.remove('form__field_active');
			}
		}
	}


	);

}


export default class ContactPage {
	constructor() {

	}
	innerFn() {
		const testForms = document.querySelectorAll(".form_js-validate");
		window.formComponent = {
			init: () => {
				testForms.forEach((form) => {

					const fields = form.querySelectorAll("[data-validate]");
					// console.log(fields)

					formComponent.validationOnSubmit(fields, form);
					formComponent.checkActive(fields, form);
					formComponent.validateOnEntry(fields, form);
				});
			},

			modelValidation: (validationFields, form) => {
				validationFields.forEach((field) => {
					formComponent.validateFields(field);
				});
				let checks = form.querySelector(".error:not(.hidden)");
				if (form.contains(checks)) {
					// here you can add some error msg or else ...
				} else {
					formSending(form);
				}
			},

			validationOnSubmit: (validationFields, form) => {
				form.addEventListener("submit", (e) => {
					e.preventDefault();
					formComponent.modelValidation(validationFields, form);
				});
			},

			checkActive: (validationFields, form) => {
				validationFields.forEach((field) => {

					field.onfocus = function () {
						let pseudoLabel = field.closest('.form__field').querySelector('.form__label');
						field.closest('.form__field').classList.add('form__field_active');

						if (!!pseudoLabel) {
							let fieldLabelWidth = ((pseudoLabel.offsetWidth * .7) / field.offsetWidth) * 100;
							field.closest('.form__field').style.setProperty("--labelWidth", fieldLabelWidth.toFixed() + '%');
						}
					}
					field.onblur = function () {
						if (field.value == '')
							field.closest('.form__field').classList.remove('form__field_active');
					}

				});
			},

			validateOnEntry: (validationFields, form) => {
				validationFields.forEach((field) => {
					field.addEventListener("input", (event) => {
						formComponent.validateFields(field);
					});
				});
			},

			validateFields: (input) => {
				const data = input.dataset.validate;

				if (~data.indexOf("no-empty")) {
					if (input.value.trim() === "") {
						formComponent.setStatus(input, "error");
					} else {
						formComponent.setStatus(input, "success");
					}
				}
				if (~data.indexOf("number")) {
					const numReg = /^\d+$/;
					if (numReg.test(input.value) && input.value.trim() !== "") {
						formComponent.setStatus(input, "success");
					} else {
						formComponent.setStatus(input, "error");
					}
				}
				if (~data.indexOf("email")) {
					const emailReg = /\S+@\S+\.\S+/;
					if (emailReg.test(input.value) && input.value.trim() !== "") {
						formComponent.setStatus(input, "success");
					} else {
						formComponent.setStatus(input, "error");
					}
				}

				if (~data.indexOf("select")) {
					if (input.value === "") {
						formComponent.setStatus(input, "error");
					} else {
						formComponent.setStatus(input, "success");
					}
				}
				if (~data.indexOf("outer")) {
					if (input.querySelector('.jdropdown-header').value === "") {
						formComponent.setStatus(input, "error");
					} else {
						formComponent.setStatus(input, "success");
					}
				}
				if (~data.indexOf("checkbox")) {
					// console.log(input.checked);
					if (input.checked) {
						formComponent.setStatus(input, "success");
					} else {
						formComponent.setStatus(input, "error");
					}
				}

				if (~data.indexOf("phone")) {
					const phoneReg =
						/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
					if (phoneReg.test(input.value) && input.value.trim() !== "") {
						formComponent.setStatus(input, "success");
					} else {
						formComponent.setStatus(input, "error");
					}
				}
			},

			// set/remove class final fx
			setStatus: (field, status) => {
				const errorMessage = field.parentElement.querySelector(".error");

				// console.log(errorMessage);
				if (status === "success") {
					field.classList.remove("input-error");
					errorMessage.classList.add("hidden");
				}
				if (status === "error") {
					field.classList.add("input-error");
					errorMessage.classList.remove("hidden");
				}
			},
		};

		formComponent.init();



		window.formSending = function (form) {
			let newFormData = new FormData(),
				action = form.action;

			if (form.id === 'contact') {
				let
					name = document.getElementById('firstname').value + ' ' + document.getElementById('lastname').value,
					email = document.getElementById('email').value,
					region = document.getElementById('country').value,
					message = document.getElementById('form_comment').value;

				newFormData.set('name', name);
				newFormData.set('email', email);
				newFormData.set('region', region);
				newFormData.set('message', message);

				// console.log(name)
				// console.log(email)
				// console.log(message)
			}
			if (form.id === 'request') {
				let
					name = document.getElementById('firstname').value + ' ' + document.getElementById('lastname').value,
					email = document.getElementById('email').value,
					location = document.querySelector('#outerSelect .jdropdown-header').value,
					practice = document.getElementById('type_of_practice').value,
					cases = document.getElementById('aligners_volume').value;


				newFormData.set('name', name);
				newFormData.set('email', email);
				newFormData.set('location', location);
				newFormData.set('cases', cases);
				newFormData.set('practice', practice);
			}

			if (form.id === 'subscribe') {
				let
					subscribeFirstname = document.getElementById('subscribeFirstname').value,
					subscribeLastname = document.getElementById('subscribeLastname').value,
					name = subscribeFirstname + ' ' + subscribeLastname,
					email = document.getElementById('subscribeEmail').value;
				newFormData.set('name', name);
				newFormData.set('email', email);
			}

			if(isLocal){
				for (var pair of newFormData.entries()) {
					console.log(pair[0]+ ', ' + pair[1]);
				}
				console.log(action)
			}
			// console.log(form);
			form.closest('.form').classList.add('is-sending')

			axios({
					method: "post",
					url: action,
					data: newFormData,
					headers: {
						"Content-Type": "multipart/form-data"
					},
				})
				.then(function (response) {
					//handle success
					console.log(response);
					showInfo('infoSuccess');
					testForms.forEach((form) => {
						const fields = form.querySelectorAll("[data-validate]");
						fields.forEach((field) => {
							if (field.type !== 'checkbox') {
								field.value = '';
							}
						})
					})
					form.closest('.form').classList.remove('is-sending')
					$('body').removeClass('modal-newsletter-is-active');
					$('body').removeClass('is-open-requestDemo');
				})
				.catch(function (response) {
					//handle error
					console.log(response);

					showInfo('infoError');
				});

		}

		let isAnimating = false;
		window.showInfo = function (elem) {
			if (!isAnimating) {
				const target = document.getElementById(elem);
				if (!!target) {
					isAnimating = true;
					target.classList.add('start-animation');
					target.classList.remove('hidden');
					setTimeout(() => {
						target.classList.add('is-animating');
					}, 200);

					setTimeout(() => {
						target.classList.remove('is-animating');
						target.classList.remove('start-animation');
						setTimeout(() => {
							target.classList.add('hidden');
						}, 500);
						isAnimating = false;
					}, 5000);
				}

			}
		}
		window.closeInfo = function (elem) {
			const target = document.getElementById(elem);
			if (!!target) {
				target.classList.add('hidden');
			}

		}



		const closePopups = document.querySelectorAll('.info-tool .close');
		if (!!closePopups) {
			closePopups.forEach((close) => {
				close.addEventListener('click', function () {
					const tool = close.closest('.info-tool').id;
					closeInfo(tool);
				})
			})
		}


		window.setFormSizes = function () {
			const form = document.querySelector(".form_js-validate");
			if (!!form) {
				document.documentElement.style.setProperty(
					"--formWidth",
					form.offsetWidth + "px"
				);
				document.documentElement.style.setProperty(
					"--formOffset",
					form.offsetLeft + "px"
				);
			}
		}
		setFormSizes();
		window.addEventListener("optimizedResize", function () {
			setFormSizes();
		});
	}

}
