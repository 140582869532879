export const subscribePopup =
	/**
	 * @param {HTMLElement} wrapper
	 * @return {void}
	 */
	(wrapper) => {
		jQuery(function ($) {
			const elem = $(wrapper);

			let previousAngle = 0,
				currentAngle = 0,
				angleDelta = 0.01;


			if(localStorage.getItem('newsletterVisible')){

			} else {
				setTimeout(() => {
					$('body').addClass('modal-newsletter-is-active');
					goTouch();
					localStorage.setItem('newsletterVisible', true)
				}, 5000);
			}

			function goTouch(){
				elem.mousemove(function(event) {
					var offset = $(this).offset();
					var centerX = offset.left + $(this).width() / 2;
					var centerY = offset.top + $(this).height() / 2;
					var mouseX = event.pageX;
					var mouseY = event.pageY;

					var radians = Math.atan2(mouseY - centerY, mouseX - centerX);
					var degrees = radians * (180 / Math.PI);

					currentAngle += (degrees - currentAngle) * angleDelta;

					wrapper.style.setProperty("--deg", currentAngle.toFixed());

					previousAngle = currentAngle;
				});
			}

		})

}
